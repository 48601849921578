import React from 'react';

import { InboxOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Select, Spin, Upload, UploadFile, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import S3 from 'aws-sdk/clients/s3';

// eslint-disable-next-line spellcheck/spell-checker
const { Dragger } = Upload;

export const GeneralForm: React.FC = () => {
    const [bucket, setBucket] = React.useState<string>('waterva.veel.shop');
    const [accessKeyId, setAccessKeyId] = React.useState<string>('');
    const [secretAccessKey, setSecretAccessKey] = React.useState<string>('');
    const [auth, setAuth] = React.useState<boolean>(true);
    const [uploading, setUploading] = React.useState<boolean>(false);

    const s3 = new S3({
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        endpoint: 'https://storage.yandexcloud.net',
        region: 'ru-central1',
    });

    const finishForm = (values: any) => {
        setAccessKeyId(values.id);
        setSecretAccessKey(values.key);
        if (values.remember) {
            localStorage.setItem('accessKeyId', values.id);
            localStorage.setItem('secretAccessKey', values.key);
        }
    };

    const handleChange = (info: UploadChangeParam<UploadFile<File>>) => {
        const { file } = info;
        handleUpload(file);
    };

    const handleUpload = async (file: UploadFile<File>) => {
        const params = {
            Bucket: bucket,
            Key: file.name,
            Body: file,
        };

        setUploading(true);

        await s3
            .upload(params)
            .promise()
            .then(() => {
                message.success('Файл успешно загружен');
                setUploading(false);
            })
            .catch(() => {
                message.error('Ошибка при загрузке файла');
                setUploading(false);
            });
    };

    React.useEffect(() => {
        const localStorageAccessKeyId = localStorage.getItem('accessKeyId');
        const localStorageSecretAccessKey = localStorage.getItem('secretAccessKey');

        if (localStorageAccessKeyId?.length && localStorageSecretAccessKey?.length) {
            setAccessKeyId(String(localStorageAccessKeyId));
            setSecretAccessKey(String(localStorageSecretAccessKey));
        }

        if (
            (accessKeyId.length && secretAccessKey.length) ||
            (localStorageAccessKeyId?.length && localStorageSecretAccessKey?.length)
        ) {
            setAuth(true);
        } else {
            setAuth(false);
        }
    }, [accessKeyId.length, secretAccessKey.length]);

    return (
        <div className={'layout'}>
            {auth ? (
                <div className={'upload'}>
                    <Spin className={'upload'} spinning={uploading}>
                        <div>Окружение:</div>
                        <Select
                            defaultValue="waterva.veel.shop"
                            onChange={setBucket}
                            disabled
                            options={[
                                { value: 'client.dev.appsol.ru', label: 'Dev Website 2.0' },
                                { value: 'client.test.appsol.ru', label: 'Test Website 2.0' },
                                { value: 'waterva.veel.shop', label: 'Prod Website 2.0' },
                            ]}
                        />
                        <Dragger
                            name="file"
                            showUploadList={false}
                            beforeUpload={() => false}
                            onChange={handleChange}
                            className={'dragger'}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Нажмите или перетащите файл в эту область для загрузки
                            </p>
                            <p className="ant-upload-hint">Одиночная загрузка файлов в S3 bucket</p>
                        </Dragger>
                    </Spin>
                </div>
            ) : (
                <div className={'form'}>
                    <Form name="form" initialValues={{ remember: true }} onFinish={finishForm}>
                        <Form.Item
                            name="id"
                            rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                        >
                            <Input.Password placeholder="accessKeyId" />
                        </Form.Item>

                        <Form.Item
                            name="key"
                            rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                        >
                            <Input.Password placeholder="secretAccessKey" />
                        </Form.Item>

                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Запомнть</Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Ок
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </div>
    );
};

export default GeneralForm;
