import React from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { GeneralForm } from './pages/General';

export const Router = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <GeneralForm />,
        },
    ]);

    return <RouterProvider router={router} />;
};
